@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?1a0yyu');
  src:  url('fonts/icomoon.eot?1a0yyu#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?1a0yyu') format('truetype'),
    url('fonts/icomoon.woff?1a0yyu') format('woff'),
    url('fonts/icomoon.svg?1a0yyu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ldi_1:before {
  content: "\e90f";
}
.icon-accomplish_1:before {
  content: "\e910";
}
.icon-accomplish_2:before {
  content: "\e911";
}
.icon-reporter_1:before {
  content: "\e912";
}
.icon-reporter_2:before {
  content: "\e913";
}
.icon-locked_in_1:before {
  content: "\e914";
}
.icon-locked_in_2:before {
  content: "\e915";
}
.icon-ct_1:before {
  content: "\e916";
}
.icon-ct_2:before {
  content: "\e917";
}
.icon-suit_1:before {
  content: "\e918";
}
.icon-suit_2:before {
  content: "\e919";
}
.icon-match_1:before {
  content: "\e91a";
}
.icon-match_2:before {
  content: "\e91b";
}
.icon-badge:before {
  content: "\e91c";
}
.icon-circle-achieve:before {
  content: "\e90d";
}
.icon-Visible:before {
  content: "\e91d";
}
.icon-Hidden:before {
  content: "\e91e";
}
.icon-Camera:before {
  content: "\e90e";
}
.icon-phone:before {
  content: "\e90c";
}
.icon-task-draw:before {
  content: "\e90b";
}
.icon-chevron:before {
  content: "\e90a";
}
.icon-chat-notify:before {
  content: "\e909";
}
.icon-not-engaging:before {
  content: "\e900";
}
.icon-kind-of-engaging:before {
  content: "\e901";
}
.icon-so-so:before {
  content: "\e902";
}
.icon-engaging:before {
  content: "\e903";
}
.icon-very-engaging:before {
  content: "\e904";
}
.icon-overdue-notify:before {
  content: "\e905";
}
.icon-task-notify:before {
  content: "\e906";
}
.icon-circle-tick:before {
  content: "\e907";
}
.icon-circle-arrow:before {
  content: "\e908";
}
